
@import url('https://fonts.googleapis.com/css2?family=Inconsolata&family=Manuale&family=Open+Sans&family=Playfair+Display+SC&display=swap');
$border-radius: 0px;
$border-radius-lg: 0px;
$border-radius-sm: 0px;
$border-radius-md: 0px;
@import '~bootstrap/scss/bootstrap';


html{
	height: 100%;
}
body {
  margin: 0;
	font-family: 'Open Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
	color: #2d2d2d;
	font-size: 24px;
	padding: 0;
	height: 100%;
	min-height: 100%;
}

#root{
	height: 100%;
}

#content{
	margin-bottom: -157px;
	min-height: 100%;
	padding-bottom: 230px;
}

.content_inner{
	max-width: 1600px;
	margin: 0 auto;
	min-height: 100%;
	position: relative;
	@media (max-width: 1600px) {
		padding-left: 30px;
		padding-right: 30px;
	}
	@media (max-width: 500px) {
		padding-left: 10px;
		padding-right: 10px;
	}
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

a {
	text-decoration: none;
	color: black;
}

* {
	box-sizing: border-box;
}

.container {

	.box {
		display: flex;
		flex-direction: row;

		&:nth-child(even) {
			flex-direction: row-reverse;
		}
		margin-bottom: 60px;

		.box_image {
			flex: 1;
			width: 50%;
			height: auto;
			align-items: center;
			display: flex;
			img {
				margin: auto;
				width: 97%;
			}
		}

		.box_description {
			display: inline-flex;
			flex: 1;
			width: 50%;
			p{
				margin: auto;
			}
			ul {
				padding-left: 50px;
				display: flex;
				flex-wrap: wrap;
				list-style: none;
				justify-content: center;
				flex-direction: column;

			}

			li {
				line-height: 1em;
				width: 100%;
				padding: 10px 0;
			}
		}

		@media (max-width: 767.98px) {
			display: block;
			margin-bottom: 30px;
			.box_image{
				width: 100%;
				margin-bottom: 30px;
				img{
					width: 100%;
				}
			}
			.box_description{
				width: 100%;
				text-align: justify;
				ul{
					padding-left: 0px;
				}
			}
		}
	}
}